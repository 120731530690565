import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/mixins';
import { SkImage } from '../../shared/image';
import { RemixIcon } from '../../shared/remix-icon';

export const DeliveredSection = (props) => {
	const { content, images } = props;
	return (
		<BgWrapper>
			<Wrapper>
				<Wave data-style="top">
					<svg viewBox="0 0 500 150" preserveAspectRatio="none">
						<path d="M-0.27,52.80 C245.20,-141.59 343.40,291.63 500.84,0.52 L500.00,150.00 L0.00,150.00 Z"></path>
					</svg>
				</Wave>
				<Container>
					<Title>{content.title}</Title>
					<Items>
						{content.rows.map((row) => (
							<Row key={row.title}>
								<RowTitle>{row.title}</RowTitle>
								<RowContent>
									<Left>
										<LeftTitle>
											<RemixIcon className={row.left_column.icon} /> {row.left_column.title}
										</LeftTitle>
										<ContentText>{row.left_column.text}</ContentText>
									</Left>
									<Center>
										<SkImage {...images.find((image) => image.name === row.image)?.image} />
										<Caption data-hint={row.hint && 'hint'}>
											{row.image_caption}
											{row.hint && (
												<Hint>
													<RemixIcon className={'information-line'} />
													<HintWrapper>{row.hint}</HintWrapper>
												</Hint>
											)}
										</Caption>
									</Center>
									<Right>
										<RightTitle>
											<RemixIcon className={row.right_column.icon} /> {row.right_column.title}
										</RightTitle>
										<ContentText>{row.right_column.text}</ContentText>
									</Right>
								</RowContent>
							</Row>
						))}
					</Items>
				</Container>
				<Wave>
					<svg viewBox="0 0 500 150" preserveAspectRatio="none">
						<path d="M-0.27,52.80 C245.20,-141.59 343.40,291.63 500.84,0.52 L500.00,150.00 L0.00,150.00 Z"></path>
					</svg>
				</Wave>
			</Wrapper>
		</BgWrapper>
	);
};

const BgWrapper = styled.div`
	background: ${(p) => p.theme.colors.bgGray};
	position: relative;
`;

const Wrapper = styled.div`
	max-width: 1460px;
	margin: 50px auto 0 auto;
	padding: 40px 16px 90px 16px;
	${media.md`
		padding: 70px 24px 130px 24px;
	`}
	${media.xl`
		padding: 120px 80px 170px 80px;
	`}
`;
const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Wave = styled.div`
	width: 100%;
	left: 0;
	position: absolute;
	bottom: -5px;
	height: 50px;
	svg {
		height: 100%;
		width: 100%;
	}
	path {
		stroke: none;
		fill: ${(p) => p.theme.colors.white};
	}
	&[data-style='top'] {
		top: -50px;
		bottom: auto;
		background: ${(p) => p.theme.colors.white};
		path {
			stroke: none;
			fill: ${(p) => p.theme.colors.bgGray};
		}
	}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.black};
	margin-bottom: 40px;
	text-align: center;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
	    line-height: 47px;
        margin-bottom: 56px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
	    line-height: 72px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	${media.md`
        gap: 40px;
	`}
	${media.xl`
        gap: 60px;
	`}
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	${media.xl`
        gap: 25px;
	`}
`;

const RowTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	line-height: 28px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.black};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
	    line-height: 40px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
	    line-height: 48px;
	`}
`;

const LeftTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	letter-spacing: 0.01em;
	display: flex;
	flex-direction: row;
	gap: 10px;
	color: ${(p) => p.theme.colors.black};
	align-items: center;
	justify-content: center;
	${media.md`
        font-weight: 600;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t5};
				`}
	    line-height: 28px;
	`}
	${media.xl`
        justify-content: start;
	`}
`;

const RightTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	letter-spacing: 0.01em;
	display: flex;
	flex-direction: row;
	gap: 10px;
	color: ${(p) => p.theme.colors.green1};
	align-items: center;
	justify-content: center;
	${media.md`
        font-weight: 600;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t5};
				`}
	    line-height: 28px;
	`}
	${media.xl`
        justify-content: start;
	`}
`;

const ContentText = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.contentGray};
	text-align: center;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
	    line-height: 22px;
        text-align: left;
	`}
`;

const RowContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 16px;
	${media.xl`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5%;
	`}
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	${media.md`gap: 16px;`}
`;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	${media.md`gap: 16px;`}
`;

const Center = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 16px;
`;

const Caption = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	text-align: center;
	color: ${(p) => p.theme.colors.contentGray};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t2};
		`}
        line-height: 20px;
	`}
	&[data-hint='hint'] {
		width: 55vw;
		${media.md`
			width: auto;
		`}
	}
`;

const Hint = styled.div`
	position: relative;
	display: inline-block;
	margin-left: 7px;
	z-index: 1;
	top: 3px;
	> span {
		color: ${(p) => p.theme.colors.black};
	}
	&:hover {
		> div {
			display: block;
			left: 50%;
			transform: translateX(-50%);
			margin-top: 8px;
		}
	}
`;

const HintWrapper = styled.div`
	width: 50vw;
	position: absolute;
	background: ${(p) => p.theme.colors.bgGray2};
	${media.md`
		width: 375px;
	`}
	display: none;
	box-sizing: border-box;
	padding: 8px 12px;
	border-radius: 6px;
	&::before {
		content: ' ';
		position: absolute;
		border: 0 solid transparent;
		border-right-width: 10px;
		border-left-width: 10px;
		border-bottom: 5px solid ${(p) => p.theme.colors.bgGray2};
		top: -5px;
		left: 50%;
		transform: translateX(-50%);
	}
`;
