import React from 'react';
import styled, { css } from 'styled-components';

import {
	Enum_Componentbaselink_Size,
	Enum_Componentbaselink_Variant,
	Enum_Componentgeneralbanner_Contentalign,
	Enum_Componentgeneralbanner_Style,
} from '@schema';

import { Banner } from '../../../components/banner';
import { media } from '../../../styles/mixins';

export const ContactUsBanner = ({ content, images }) => {
	return (
		<Background>
			<Wrapper>
				<Banner
					contentAlign={Enum_Componentgeneralbanner_Contentalign.Left}
					id={'openchannel banner'}
					style={Enum_Componentgeneralbanner_Style.Light}
					title={`{"time":1661856452288,"blocks":[{"id":"_fCinARX92","type":"paragraph","data":{"text":"${content.title}","alignment":"left"}}],"version":"2.23.2"}`}
					description={`{"time":1661856452288,"blocks":[{"id":"_fCinARX92","type":"paragraph","data":{"text":"${content.text}","alignment":"left"}}],"version":"2.23.2"}`}
					image={images.find((image) => image.name === content.background_image).image}
					link={{
						id: 'openchannel banner link',
						variant: Enum_Componentbaselink_Variant.Primary,
						text: `${content.link_text}`,
						external: false,
						size: Enum_Componentbaselink_Size.Medium,
					}}
				/>
			</Wrapper>
		</Background>
	);
};

const Background = styled.div`
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
`;

const Wrapper = styled.div`
	padding: 80px 0 40px 0;
	max-width: 1460px;
	> div {
		min-height: auto;
		background: ${(p) => p.theme.colors.bannerGray};
		padding: 80px 16px;
		${media.md`
            padding: 60px 24px;
	    `}
		${media.xl`
            padding: 80px 97px;
	    `}
	}
	${media.md`
        padding: 60px 24px 120px 24px;
	`}
	${media.xl`
        padding: 160px 80px 80px 80px;
        margin: 0 auto;
	`}
    ${media.xxl`
        padding: 160px 0 80px 0;
        margin: 0 auto;
	`}
`;
