import React from 'react';
import styled, { css } from 'styled-components';

import { Breadcrumbs } from '../../../components/breadcrumbs';
import { media } from '../../../styles/mixins';
import { SkImage } from '../../shared/image';
import { Link } from '../../shared/link';

export const OpenChannelHero = ({ data, images, path }) => {
	return (
		<Container>
			<Breadcrumbs
				isWrapped
				slug={path || '/case-studies/all-in-one-whitelabel-app-store-platform/'}
			/>
			<Wrapper>
				<Content>
					<ImageWrapper>
						<SkImage {...images.find((image) => image.name === data.side_image).image} />
					</ImageWrapper>
					<TextWrapper>
						<TitleWrapper>
							<Icon>
								<SkImage {...images.find((image) => image.name === data.company_logo).image} />
							</Icon>
							<Title>{data.title}</Title>
						</TitleWrapper>
						<TagsWrapper>
							{data.tags.map((tag) => (
								<Tag key={tag}>{tag}</Tag>
							))}
						</TagsWrapper>
						<Description>{data.description}</Description>
						<LinkWrapper>
							<Link label={data.link.label} remixIcon={data.link.icon} url={data.link.url} />
						</LinkWrapper>
					</TextWrapper>
				</Content>
			</Wrapper>
			<Wave>
				<svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
					<path
						d="M-0.27,52.80 C245.20,-141.59 343.40,291.63 500.84,0.52 L500.00,150.00 L0.00,150.00 Z"
						style="stroke: none; fill: #ffffff;"
					></path>
				</svg>
			</Wave>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	overflow: hidden;
	background: ${(p) => p.theme.gradients.hero};
`;

const Content = styled.div`
	max-width: 1460px;
	width: 100%;
	margin: 0 auto;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	margin-top: -22px;
	> nav {
		width: 100%;
		padding: 16px 0;
		position: relative;
		z-index: 5;
		a:hover {
			background: linear-gradient(90deg, #ffffff 0%, #ffffff 0.01%, #ffffff 100%) !important;
			-webkit-background-clip: text !important;
		}
	}
	padding: 24px 16px 84px 16px;
	${media.md`
		padding: 12px 24px 130px 24px;
	`}
	${media.xl`
		padding: 198px 80px 250px 80px;
	`}
	${media.xxl`
		padding: 198px 0 300px 0;
		margin: 0 auto;
	`}
	background: ${(p) => p.theme.gradients.hero};
`;

const ImageWrapper = styled.div`
	position: relative;
	margin-bottom: 24px;
	> img {
		min-width: 150%;
		margin-left: -25%;
		${media.md`
			min-width: 100%;
			width: 100%;
			margin-left: 0;
			height: auto;
		`}
		${media.xl`
			height: 100%;
		`}
	}
	${media.md`
		margin-bottom: 40px;
	`}
	${media.xl`
		position: absolute;
		right: -360px;
		top: 50%;
		transform: translate(0%, -50%); 
		height: 525px;
	`}
	${media.xxl`
		right: -150px;
	`}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 24px;
	`}
	${media.xl`
        width: 60%;
	`}
	${media.xxl`
		width: 55%;
	`}
`;
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	${media.md`
        gap: 16px;
	`}
	${media.xl`
    	gap: 24px;
		margin-bottom: 16px;
	`}
`;
const Title = styled.p`
	color: ${(p) => p.theme.colors.white};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	letter-spacing: 0.01em;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
		line-height: 48px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
		line-height: 72px;
	`}
`;
const TagsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
`;
const Tag = styled.div`
	color: ${(p) => p.theme.colors.gray};
	font-weight: 400;
	background: ${(p) => p.theme.colors.transparentWhite};
	border-radius: 100px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	padding: 6px 12px;
	line-height: 16px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h6};
				`}
		line-height: 22px;
		padding: 7px 16px;
	`}
`;
const Description = styled.p`
	color: ${(p) => p.theme.colors.white};
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h6};
	`}
	line-height: 22px;
	${media.md`
        width: 50%;
	`}
	${media.xl`
        font-size: 18px;
		line-height: 24px;
		width: 85%;
	`}
`;

const Icon = styled.div`
	width: 40px;
	> img {
		width: 100%;
	}
	${media.md`
		width: 48px;
	`}
	${media.xl`
		width: 76px;
	`}
`;

const LinkWrapper = styled.div`
	> a {
		background: ${(p) => p.theme.colors.green};
		font-weight: 500;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height:22px;
		&:hover {
			background: ${(p) => p.theme.colors.green};
			gap: 8px;
		}
	}
`;

const Wave = styled.div`
	width: 100%;
	position: absolute;
	bottom: -5px;
	height: 50px;
	${media.md`
		height: 70px;
	`}
	${media.xl`
		height: 100px;
	`}
`;
