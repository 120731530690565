import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/mixins';
import { SkImage } from '../../shared/image';

export const Marketplace = ({ content, images }) => {
	return (
		<Container>
			<Wrapper>
				<TitleWrapper>
					<Title>{content.title}</Title>
				</TitleWrapper>
				<Content>
					{content.content_rows.map((item, index) => (
						<RowWrapper key={item.title}>
							<Row data-right={(index + 1) % 2 === 0 ? 'true' : 'false'}>
								<TextContent>
									<RowTitle>{item.title}</RowTitle>
									{item.sub_text && <RowSubtitle>{item.sub_text}</RowSubtitle>}
									{item.text && <RowText>{item.text}</RowText>}
								</TextContent>
								<ImageWrapper data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}>
									{images.find((image) => image.name === item.image) && (
										<SkImage {...images.find((image) => image.name === item.image).image} />
									)}
								</ImageWrapper>
							</Row>
						</RowWrapper>
					))}
				</Content>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
`;
const RowWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column-reverse;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const TitleWrapper = styled.div`
	padding: 0 16px 0 16px;
	${media.md`
		padding: 0 24px 0 24px;
	`}
	${media.xl`
		padding: 0 80px 0 80px;
	`}
	${media.xxl`       
		padding: 0;
		max-width: 1460px;
		width: 1460px;
		margin: 0 auto;
	`}
`;

const Title = styled.h2`
	font-weight: 600;
	height: fit-content;
	margin-bottom: 24px;
	color: ${(p) => p.theme.colors.black};
	letter-spacing: 0.01em;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	width: 67%;
	${media.md`
        width: 47%;
		margin-bottom: 40px;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
	    line-height: 48px;
	`}
	${media.xl`
		margin-bottom: 60px;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
		line-height: 72px;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 60px;
	margin-bottom: 40px;
	${media.md`
        gap: 40px;
		margin-bottom: 120px;
    `}
	${media.xl`
        gap: 60px;
		margin-bottom: 160px;
    `}
`;

const Row = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 16px;
	${media.md`
        flex-direction: row;
		justify-content: space-between;
        gap: 40px;
        align-items: center;
	`}
	${media.xl`
		gap: 105px;
	`}
    &[data-right='true'] {
		${media.md`
            flex-direction: row-reverse;
	    `}
	}
	max-width: 1460px;
	margin: 0 auto;
	padding: 0 16px 0 16px;
	${media.md`
		padding: 0 24px 0 24px;
	`}
	${media.xl`
		padding: 0 80px 0 80px;
		margin: 0 auto 0 auto; 
	`}
	${media.xxl`       
		padding: 0;
	`}
`;

const RowTitle = styled.h3`
	font-weight: 600;
	color: ${(p) => p.theme.colors.black};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	line-height: 28px;
	letter-spacing: 0.01em;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
	    line-height: 40px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
	    line-height: 48px;
	`}
`;

const RowSubtitle = styled.p`
	font-weight: 500;
	color: ${(p) => p.theme.colors.black};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
	position: relative;
	&::after {
		content: ' ';
		position: absolute;
		bottom: -12px;
		left: 0;
		width: 80px;
		border: 1px solid ${(p) => p.theme.colors.green};
		${media.xl`
            bottom: -16px;
	    `}
	}
`;

const RowText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	margin-top: 12px;
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray1};
	${media.md`
        margin-top: 8px;
    `}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
        line-height: 22px;
        margin-top: 0;
	`}
`;

const TextContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: calc(100vw - 32px);
	${media.md`
		width: auto;
        gap: 16px;
        width: 50%;
		max-width: 50%;
    `}
	${media.xl`
        gap: 32px;
    `}
`;

const ImageWrapper = styled.div`
	height: 275px;
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	img {
		height: 100%;
		width: 460px;
		min-width: 460px;
		margin-right: -16px;
	}
	${media.md`
        height: 310px;
        width: 50%;
		max-width: 50%;
		top: 0;
		right: 0;
		margin-left: 0;
		margin-right: -24px;
		flex-direction: row;
		margin-right: 0;
	`}
	${media.lg`		
		flex-direction: row-reverse;
	`}

	${media.xl`
        height: 430px;
		margin-right: 0;
    `}
	${media.xxl`
        height: 525px;
    `}
	${media.xl`
        img {
			position: absolute;
			height: 100%;
			width: 640px;
			min-width: 640px;
			right: 0;
		}
    `}
	${media.xxl`
        img {
			width: 840px;
			min-width: 840px;
			right: 0;
		}
    `}
	&[data-left='true'] {
		flex-direction: row;
		margin-left: 0;
		${media.md`
			flex-direction: row-reverse;
			display: flex;
			margin-left: -24px;
			margin-right: 0;
		`}
		${media.lg`
			flex-direction: row;
		`}
		${media.xl`
			margin-left: 0;
		`}
		img {
			margin-left: -16px;
			${media.md`
				left: 0;
			`}
		}
	}
	@media (max-width: 450px) {
		&[data-left='true'] {
			max-width: calc(100vw - 32px);
			flex-direction: row-reverse;
			position: relative;
			img {
				position: absolute;
				right: 16px;
			}
		}
	}
`;
