import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMediaQuery } from '@hooks';

import { media } from '../../../styles/mixins';
import { SkImage } from '../../shared/image';
import { Link } from '../../shared/link';

export const OurResults = ({ content, images }) => {
	const isDesktop = useMediaQuery(`(min-width: 550px)`);
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		isDesktop ? setIsMobile(false) : setIsMobile(true);
	}, [isDesktop]);
	return (
		<Background>
			<Container>
				<Wrapper>
					<Title>{content.title}</Title>
					<Image>
						<SkImage
							{...images.find((image) =>
								isMobile ? image.name === `${content.image}_mobile` : image.name === content.image,
							).image}
						/>
					</Image>
					<Contact>
						<ContactTitle>{content.contact_form.title}</ContactTitle>
						<ContactText>{content.contact_form.text}</ContactText>
						<Link url="/contact-us" label={content.contact_form.link_text} variant="primary" />
					</Contact>
				</Wrapper>
			</Container>
		</Background>
	);
};

const Background = styled.div`
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
	${media.xl`
		padding: 80px 0 160px 0; 
	`}
`;

const Container = styled.div`
	max-width: 1460px;
	margin: 0 auto;
	padding: 40px 16px 80px 16px;
	${media.md`
		padding: 60px 24px 120px 24px;
	`}
	${media.xl`
		padding: 0 80px 0 80px;
		margin: 0 auto; 
	`}
	${media.xxl`       
		padding: 0;
	`}
`;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	${media.xl`
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 8px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	height: fit-content;
	margin-bottom: 24px;
	color: ${(p) => p.theme.colors.black};
	letter-spacing: 0.01em;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h3};
	`}
	line-height: 48px;
	${media.md`
		margin-bottom: 40px;
	`}
	${media.xl`
		margin-bottom: 0;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
		line-height: 72px;
	`}
`;

const Image = styled.div`
	> img {
		width: 100%;
	}
	${media.xl`
		> img {
			width: auto;
		}
	`}
`;

const Contact = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	margin-top: 24px;
	${media.xl`
		width:380px;
		align-items: start;
		position: absolute;
		left: 0;
		bottom: 0;
	`}
	a {
		color: ${(p) => p.theme.colors.white};
		width: fit-content;
		z-index: 1;
	}
`;

const ContactTitle = styled.p`
	color: ${(p) => p.theme.colors.black};
	font-weight: 500;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
		text-align: left;
	`}
`;

const ContactText = styled.p`
	color: ${(p) => p.theme.colors.gray1};
	font-weight: 400;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.md`
		font-weight: 500;
	`}
	${media.xl`
		font-weight: 400;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
		text-align: left;
	`}
`;
