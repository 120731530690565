import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/mixins';
import { SkImage } from '../../shared/image';
import { RemixIcon } from '../../shared/remix-icon';

export const OpenChannelFeaturedSection = ({ content }) => {
	return (
		<Wrapper>
			<TopElipse />
			<Container>
				<Content>
					<Left>
						<ImageWrapper>
							<SkImage
								url="https://www.softkit.dev/static/images/Name_Open_Channel_Color_Yes_f5dea5d123.svg?updated_at=2022-11-24T17:16:53.636Z"
								alt="open channel logo"
							/>
						</ImageWrapper>
						<PlusRemixIcon className="ri-add-fill" />
						<ImageWrapper>
							<SkImage
								url="https://www.softkit.dev/static/images/Stripe_Logo_revised_2016_1_eff306bad8.svg?updated_at=2022-12-14T09:13:40.843Z"
								alt="stripe logo"
							/>
						</ImageWrapper>
					</Left>
					<Right>
						<Title>{content.title}</Title>
						<Description>{content.description}</Description>
					</Right>
				</Content>
			</Container>
			<BottomElipse />
		</Wrapper>
	);
};

const Container = styled.div`
	padding: 40px 16px;
	${media.md`
        padding: 80px 24px;
	`}
	${media.lg`
        padding: 153px 24px;
	`}
	${media.xxl`
        padding: 153px 267px;
	`}
	position: relative;
	&::before {
		content: ' ';
		position: absolute;
		background: ${(p) => p.theme.gradients.openChannelBlue};
		width: 102%;
		height: 100%;
		left: -1%;
		top: 0;
	}
`;

const Content = styled.div`
	display: flex;
	margin: 0 auto;
	max-width: 1146px;
	flex-direction: column;
	gap: 24px;
	position: inherit;
	${media.md`
        gap: 40px;
	`}
	${media.lg`
        gap: 140px;
		flex-direction: row;
		justify-content: space-between;
	`}
	${media.xl`
        gap: 235px;
	`}
	${media.xxl`
        gap: 235px;
	`}
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 22px;
	${media.lg`
        width: 480px;
	`}
	${media.xl`
        gap: 30px;
	`}
`;

const Right = styled.div`
	color: ${(p) => p.theme.colors.white};
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.lg`
        gap: 24px;
	`}
`;

const ImageWrapper = styled.div`
	height: 36px;
	display: flex;
	> img {
		height: 100%;
		margin: 0 auto;
	}
`;

const PlusRemixIcon = styled((p) => <RemixIcon {...p} />)`
	color: ${(p) => p.theme.colors.white};
	font-size: 32px;
`;

const Title = styled.p`
	text-align: center;
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	letter-spacing: 0.01em;
	${media.md`
		font-size: 28px;
		line-height: 40px;
	`}
	${media.lg`
		text-align: left;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
			line-height: 48px;
		`}
`;

const Description = styled.p`
	font-weight: 400;
	line-height: 22px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	text-align: center;
	${media.lg`
		text-align: left;
	`}
`;

const TopElipse = styled.div`
	height: 50px;
	width: 100%;
	clip-path: ellipse(52% 50px at 50% 102%);
	background: ${(p) => p.theme.gradients.openChannelBlue};
`;

const BottomElipse = styled.div`
	height: 50px;
	width: 102%;
	margin-left: -1%;
	background: ${(p) => p.theme.gradients.openChannelBlue};
	position: relative;
	overflow: hidden;
	&::after {
		content: ' ';
		position: absolute;
		border-radius: 50% 50% 0 0;
		width: 100%;
		height: 100px;
		background: ${(p) => p.theme.colors.white};
		bottom: -50px;
	}
`;

const Wrapper = styled.div`
	background: ${(p) => p.theme.colors.white};
	overflow: hidden;
`;
