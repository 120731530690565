import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../../styles/mixins';
import { RemixIcon } from '../../shared/remix-icon';

export const CustomFunctionality = ({ content }) => {
	return (
		<Background>
			<Container>
				<Title>{content.title}</Title>
				<Cards>
					{content.card_list.map((card) => (
						<Card key={card.text}>
							<CardText>{card.text}</CardText>
							<CardIcon>
								<RemixIcon className={card.icon} />
							</CardIcon>
						</Card>
					))}
				</Cards>
			</Container>
		</Background>
	);
};

const Background = styled.div`
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1460px;
	margin: 0 auto;
	padding: 60px 16px 75px 16px;
	${media.md`
		padding: 80px 24px 100px 24px;
	`}
	${media.xl`
		padding: 100px 80px 120px 80px;
        flex-direction: row;
	`}
	${media.xxl`
		padding: 100px 0 120px 0;
	`}
`;

const Title = styled.p`
	height: fit-content;
	position: relative;
	min-width: 300px;
	color: ${(p) => p.theme.colors.black};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	letter-spacing: 0.01em;
	margin-bottom: 40px;
	${media.md`
        min-width: 350px;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
	    line-height: 40px;
        margin-bottom: 64px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
	    line-height: 48px;
        min-width: 450px;
	`}
    &::after {
		content: ' ';
		position: absolute;
		width: 60px;
		bottom: -16px;
		border: 1px solid ${(p) => p.theme.colors.green};
		left: 0;
		${media.md`
            bottom: -24px;
	    `}
	}
`;

const Cards = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
	`}
	${media.xl`
        gap: 8px;
	`}
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	${media.md`
        justify-content: space-between
    `}
	${media.xl`
        padding: 24px;
	`}
	cursor: auto;
	${transition('all')};
	${(p) => css`
		&:hover {
			background: ${(p) => p.theme.gradients.openChannelCardHover};
			> p {
				color: ${(p) => p.theme.colors.white};
				${transition('all')};
			}
			span {
				${transition('all')};
				color: ${(p) => p.theme.colors.white};
			}
		}
	`}
	border-radius: 8px;
`;

const CardText = styled.p`
	font-weight: 400;
	margin-bottom: 18px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	${media.xl`
        font-weight: 500;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
        line-height: 24px;
        margin-bottom: 35px;
    `}
	color: ${(p) => p.theme.colors.black};
`;

const CardIcon = styled.div`
	color: ${(p) => p.theme.colors.black};
	font-size: 21px;
	${media.xl`
        font-size: 28px;
    `}
`;
