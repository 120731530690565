import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/mixins';
import { RemixIcon } from '../../shared/remix-icon';

export const WhatWeDid = ({ content }) => {
	return (
		<Container>
			<Wrapper>
				<Title>{content.title}</Title>
				<Cards>
					{content.card_list.map((card) => (
						<Card key={card.text}>
							<Icon background={card.background}>
								<RemixIcon className={card.icon} />
							</Icon>
							<CardText>{card.text}</CardText>
						</Card>
					))}
				</Cards>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	padding: 40px 8px;
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
	${media.md`
        padding: 60px 24px;
	`}
	${media.xl`
        padding: 80px 40px 160px 40px;
	`}
`;

const Wrapper = styled.div`
	background: ${(p) => p.theme.colors.bgGray};
	border-radius: 24px;
	padding: 40px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	${media.md`
        padding: 60px 24px;
	`}
	${media.xl`
        padding: 80px 195px 160px 195px;
        max-width: 1460px;
        margin: 0 auto;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	color: ${(p) => p.theme.colors.black};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.01em;
	margin-bottom: 24px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
	    line-height: 48px;
        margin: 0 15% 24px 15%;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
	    line-height: 72px;
        margin: 0 15% 40px 15%;
	`}
`;

const Cards = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 16px;
    `}
	${media.xl`
        grid-row-gap: 24px;
    `}
`;

const Card = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	${media.xl`
        gap: 16px;
    `}
`;

type StyledTypes = {
	background: string;
};

const Icon = styled.div<StyledTypes>`
	font-size: 21px;
	color: ${(p) => p.theme.colors.white};
	padding: 14px;
	max-width: 48px;
	max-height: 48px;
	border-radius: 8px;
	background: ${(props) => props.background};
	${media.xl`
        padding: 19px;
        max-width: 60px;
        max-height: 60px;
    `}
`;

const CardText = styled.p`
	font-weight: 400;
	line-height: 20px;
	color: ${(p) => p.theme.colors.black};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	${media.md`
        line-height: 22px;
	    ${(p) => css`
				font-size: ${p.theme.typography.sizes.t3};
			`}
    `}
`;
