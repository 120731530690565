import React from 'react';
import { navigate } from 'gatsby';

import { PopupProvider } from '@context/popup-context';
import { TemplateProps } from '@templates/types';

import { GetInTouch } from '../../components/get-in-touch';
import { AboutUs } from '../../components/openchannel/about-us';
import { ContactUsBanner } from '../../components/openchannel/banner';
import { CustomFunctionality } from '../../components/openchannel/custom-functionality';
import { DeliveredSection } from '../../components/openchannel/delivered-section';
import { EssentialFeatures } from '../../components/openchannel/essential-features';
import { OpenChannelFeaturedSection } from '../../components/openchannel/featured-section';
import { OpenChannelHero } from '../../components/openchannel/hero-section';
import { Marketplace } from '../../components/openchannel/marketplace';
import { OurResults } from '../../components/openchannel/our-results';
import { ProjectTeamDetails } from '../../components/openchannel/project-team-details';
import { WhatWeDid } from '../../components/openchannel/what-we-did';
import { Popup } from '../../components/popup';
import { ReviewSection } from '../../components/review';
import { Seo } from '../../components/shared/seo';
import MainLayout from '../../layouts/main-layout';

export const OpenChannelTemplate = (props: TemplateProps) => {
	const { page, menu, header, footer, cookieBanner, theme, ctaPopup, seo } = props.pageContext;
	const attributes = page.attributes;

	if (!page) {
		return navigate('/404');
	}
	const { GlobalMeta } = seo.data.attributes;

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
			popup={ctaPopup}
		>
			<Seo meta={page.attributes.meta} globalMeta={GlobalMeta} url={page.attributes.url} />
			{attributes && (
				<PopupProvider>
					<OpenChannelHero
						data={attributes.content.hero}
						images={attributes.images}
						path={attributes.url}
					/>
					<AboutUs data={attributes.content.section_1} images={attributes.images} />
					<OpenChannelFeaturedSection content={attributes.content.section_2} />
					<ProjectTeamDetails cardList={attributes.content.section_3.card_list} />
					<WhatWeDid content={attributes.content.section_4} />
					<CustomFunctionality content={attributes.content.section_5} />
					<OurResults content={attributes.content.section_6} images={attributes.images} />
					<DeliveredSection content={attributes.content.section_7} images={attributes.images} />
					<ContactUsBanner content={attributes.content.section_8} images={attributes.images} />
					<EssentialFeatures content={attributes.content.section_9} />
					<Marketplace content={attributes.content.section_10} images={attributes.images} />
					<ReviewSection data={attributes.sections.data[0]} />
					<GetInTouch images={attributes.images} />
					{ctaPopup?.data && (
						<Popup
							form={ctaPopup.data.attributes.form}
							formResponse={ctaPopup.data.attributes.formResponse}
						/>
					)}
				</PopupProvider>
			)}
		</MainLayout>
	);
};
