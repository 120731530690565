import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../../styles/mixins';
import { RemixIcon } from '../../shared/remix-icon';

export const EssentialFeatures = ({ content }) => {
	return (
		<Background>
			<Container>
				<Wrapper>
					<Title>{content.title}</Title>
					<Cards>
						{content.card_list.map((card) => (
							<Card key={card.title}>
								<CardTitle>{card.title}</CardTitle>
								<CardText>{card.text}</CardText>
								<IconWrapper>
									<Icon background={card.background}>
										<RemixIcon className={card.icon} />
									</Icon>
								</IconWrapper>
							</Card>
						))}
					</Cards>
				</Wrapper>
			</Container>
		</Background>
	);
};

const Background = styled.div`
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
	${media.xl`
		padding: 80px 0 160px 0; 
	`}
`;

const Container = styled.div`
	max-width: 1460px;
	margin: 0 auto;
	padding: 40px 16px 80px 16px;
	${media.md`
		padding: 60px 24px 120px 24px;
	`}
	${media.xl`
		padding: 0 80px 0 80px;
		margin: 0 auto ; 
	`}
	${media.xxl`       
		padding: 0;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		gap: 40px;
	`}
	${media.xl`
		gap: 60px;
	`}
`;

const Title = styled.p`
	color: ${(p) => p.theme.colors.black};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.01em;
	padding: 16px;
	${media.md`
        margin: 0 auto;
		gap: 24px;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
	    line-height: 40px;
        width: 75%;
	`}
	${media.xl`
		gap: 40px;
        width: 55%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
	    line-height: 48px;
	`}
    border: 1px dashed ${(p) => p.theme.colors.transparentGreen};
	border-radius: 16px;
`;

const Cards = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    `}
	${media.xl`
        grid-template-columns: repeat(4, 1fr);
    `}
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	padding: 16px;
	border: 1px dashed ${(p) => p.theme.colors.bgGray2};
	border-radius: 8px;
	${media.md`
        padding: 24px;
    `}
	${media.xl`
        gap: 8px;
    `}
    ${transition('all')};
	&:hover {
		box-shadow: ${(p) => p.theme.shadows.light};
		background: ${(p) => p.theme.colors.lightGray};
		border-style: solid;
		border-color: ${(p) => p.theme.colors.lightGray};
	}
	&:nth-child(1) {
		> div {
			> div {
				background: linear-gradient(135deg, #fad661 0%, #fea671 100%);
			}
		}
	}
	&:nth-child(2) {
		> div {
			> div {
				background: linear-gradient(97.98deg, #5ea9eb 0%, #3f75e0 99.99%);
			}
		}
	}
	&:nth-child(3) {
		> div {
			> div {
				background: linear-gradient(90deg, #00cf9f 0%, #00cfcf 100%);
			}
		}
	}
	&:nth-child(4) {
		> div {
			> div {
				background: linear-gradient(135deg, #ff9d79 0%, #ff8c8f 100%);
			}
		}
	}
	&:nth-child(5) {
		> div {
			> div {
				background: linear-gradient(105.5deg, #635ae0 -16.06%, #36a8e0 87.08%);
			}
		}
	}
	&:nth-child(6) {
		> div {
			> div {
				background: linear-gradient(135deg, #b7adff 0%, #ed9dff 100%);
			}
		}
	}
	&:nth-child(7) {
		> div {
			> div {
				background: linear-gradient(135deg, #7ec1f2 0%, #4b92e2 100%);
			}
		}
	}
`;

const CardTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	color: ${(p) => p.theme.colors.black};
	line-height: 22px;
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
	    line-height: 24px;
	`}
`;

const CardText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray1};
`;

type StyledTypes = {
	background: string;
};

const Icon = styled.div<StyledTypes>`
	font-size: 21px;
	color: ${(p) => p.theme.colors.white};
	padding: 14px;
	max-width: 48px;
	max-height: 48px;
	border-radius: 8px;
	margin-top: 6px;
	${media.md`
        margin-top: 10px;
    `}
	${media.xl`
    margin-top: 8px;
        padding: 19px;
        max-width: 60px;
        max-height: 60px;
    `}
`;

const IconWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: end;
`;
