import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../../styles/mixins';

export const ProjectTeamDetails = (props) => {
	const { cardList } = props;
	return (
		<Background>
			<Wrapper>
				{cardList.map((card) => (
					<CardWrapper key={card.title}>
						<Title>{card.value}</Title>
						<Text>{card.text}</Text>
					</CardWrapper>
				))}
			</Wrapper>
		</Background>
	);
};

const Background = styled.div`
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 68px 16px 40px 16px;
	gap: 16px;
	${media.md`
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 8px;
		padding: 120px 24px 60px 16px;
	`}
	${media.xl`
		padding: 160px 80px 80px 80px;
		max-width: 1460px;
		margin: 0 auto;
	`}
	${media.xxl`
	padding: 160px 0px 80px 0px;
	`}
`;

const CardWrapper = styled.div`
	height: 100%;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	> p {
		${transition('all')};
	}
	cursor: auto;
	${transition('all')};
	${media.md`
        padding: 24px;
	`}
	${media.lg`
		gap: 8px
	`}
	${(p) => css`
		&:hover {
			background: ${(p) => p.theme.gradients.openChannelCardHover};
			> p {
				color: ${(p) => p.theme.colors.white};
			}
		}
	`}
`;

const Title = styled.p`
	color: ${(p) => p.theme.colors.black};
	font-weight: 600;
	font-size: 28px;
	line-height: 40px;
	letter-spacing: 0.01em;
	text-align: center;
	${media.md`
		text-align: left;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		font-weight: 500;
		line-height: 48px;
	`}
`;

const Text = styled.p`
	color: ${(p) => p.theme.colors.gray1};
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	text-align: center;
	${media.md`
		text-align: left;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`};
`;
