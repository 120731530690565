import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../../styles/mixins';
import { SkImage } from '../../shared/image';
import { RemixIcon } from '../../shared/remix-icon';

export const AboutUs = (props) => {
	const { data, images } = props;
	return (
		<Background>
			<Container>
				<Title>{data.title}</Title>
				<MainInfo>
					<InfoText>{data.primary_description}</InfoText>
					<Info>
						{data.info.map((item) => (
							<InfoItem key={item.key}>
								<ItemIcon>
									<RemixIcon className={item.icon} />
								</ItemIcon>
								<ItemContent>
									<ItemTitle>{item.key}</ItemTitle>
									<ItemValue>{item.value}</ItemValue>
								</ItemContent>
							</InfoItem>
						))}
					</Info>
				</MainInfo>
				<SecondaryInfo>
					<SecondaryContent>
						<SecondaryTitle>{data.secondary_description.title}</SecondaryTitle>
						<SecondarySubtitle>{data.secondary_description.text}</SecondarySubtitle>
						<ListTitle>{data.secondary_description.list_title}</ListTitle>
						<List>
							{data.secondary_description.list.map((text) => (
								<ListItem key={text}>
									<RemixIcon className="ri-checkbox-circle-fill" />
									<ItemText>{text}</ItemText>
								</ListItem>
							))}
						</List>
					</SecondaryContent>
					<CardsWrapper>
						<Cards>
							{data.secondary_description.cards.map((card) => (
								<Card key={card.text}>
									<CardTop>
										<CardTitle>
											{card.icon && <RemixIcon className={card.icon} />}
											{card.value}
										</CardTitle>
										{card.image && (
											<CardImage>
												<SkImage {...images.find((image) => image.name === card.image)?.image} />
											</CardImage>
										)}
									</CardTop>
									<CardText>{card.text}</CardText>
								</Card>
							))}
						</Cards>
						<CardsCaption>{data.secondary_description.cards_caption}</CardsCaption>
					</CardsWrapper>
				</SecondaryInfo>
			</Container>
		</Background>
	);
};

const Background = styled.div`
	${(p) => css`
		background: ${(p) => p.theme.colors.white};
	`}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1460px;
	margin: 0 auto;
	padding: 60px 16px 75px 16px;
	${media.md`
		padding: 80px 24px 100px 24px;
	`}
	${media.xl`
		padding: 100px 80px 120px 80px;
	`}
	${media.xxl`
		padding: 100px 0 120px 0;
	`}
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	gsp: 4px;
	${media.xl`
		padding: 24px;
	`}
	&:hover {
		${media.xl`
			${(p) => css`
				background: ${(p) => p.theme.gradients.openChannelCardHover};
			`}
		`}
		p {
			${media.xl`
				${(p) => css`
					color: ${(p) => p.theme.colors.white};
				`}
			`}
		}
	}
`;

const CardTop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const CardTitle = styled.p`
	font-weight: 600;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t6};
	`}
	> span {
		font-size: 22px;
	}
	line-height: 40px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.black};
	${media.md`
		> span {
		font-size: 32px;
		}
	`}
	${media.xl`
		font-weight: 500;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${transition('all')};
`;

const CardText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray1};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${transition('all')};
`;

const CardImage = styled.div`
	width: 50px;
	${media.xl`
		width: 65px;
	`}
`;

const Title = styled.div`
	font-weight: 600;
	color: ${(p) => p.theme.colors.black};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	letter-spacing: 0.01em;
	margin-bottom: 24px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
		line-height: 48px;
        margin-bottom: 40px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
		line-height: 72px;
        margin-bottom: 60px;
	`}
`;

const MainInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 44px;
	${media.md`
		margin-bottom: 60px;
	`}
	${media.xl`
        flex-direction: row;
		margin-bottom: 120px;
		gap: 15%;
	`}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	${media.xl`
        min-width: 285px;
	`}
`;

const InfoText = styled.p`
	font-weight: 400;
	color: ${(p) => p.theme.colors.black};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	margin-bottom: 24px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
		line-height: 22px;
	`}
	${media.xl`
        margin-bottom: 0;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
		line-height: 24px;
	`}
`;

const InfoItem = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	${media.xl`
        gap: 16px;
	`}
`;

const ItemIcon = styled.div`
	padding: 15px;
	background: ${(p) => p.theme.colors.lightGray};
	border-radius: 8px;
	> span {
		color: ${(p) => p.theme.colors.green};
		font-size: 19px;
	}
`;

const ItemTitle = styled.p`
	font-weight: 500;
	color: ${(p) => p.theme.colors.gray1};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.xl`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
	    line-height: 22px;
	`}
`;

const ItemValue = styled.p`
	font-weight: 400;
	color: ${(p) => p.theme.colors.black};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h6};
	`}
	line-height: 22px;
	${media.xl`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
	    line-height: 24px;
	`}
`;

const ItemContent = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const SecondaryInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	${media.xl`
        flex-direction: row;
		gap: 10%;
	`}
`;

const SecondaryContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	${media.xl`
		max-width: 480px
	`}
	${media.xxl`
		max-width: 780px
	`}
`;

const SecondaryTitle = styled.p`
	background: ${(p) => p.theme.gradients.openChannelCardHover};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 500;
	margin-bottom: 4px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	${media.md`
		font-weight: 600;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h5};
				`}
		line-height: 28px;
	`}
	letter-spacing: 0.01em;
`;

const SecondarySubtitle = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	margin-bottom: 24px;
	color: ${(p) => p.theme.colors.black};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
		line-height: 22px;
		margin-bottom: 40px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
		line-height: 24px;
	`}
`;

const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const ListTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	line-height: 28px;
	margin-bottom: 16px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.black};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
		line-height: 32px;
	`}
`;

const ListItem = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	${media.xl`
		gap: 20px;
	`}
	> span {
		color: ${(p) => p.theme.colors.green};
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h5};
		`}
	}
`;

const ItemText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.black};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
		line-height: 24px;
	`}
`;

const Cards = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 24px;
	${media.xl`
        gap: 8px;
	`}
`;

const CardsWrapper = styled.div`
	width: 100%;
	margin-top: 24px;
	${media.xl`
		margin-top: 0;
	`}
`;

const CardsCaption = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	color: ${(p) => p.theme.colors.gray1};
	text-align: center;
	width: 100%;
	margin-top: 16px;
`;
